$(function() {
  let pageHash = window.location.hash;
  if (pageHash) {
      let scrollToElement = $('[data-id="' + pageHash + '"]');
      if (!scrollToElement.length) return;
      $(window).on('load', function() {
          history.replaceState('', '', './');
          let locationOffset = scrollToElement.offset().top;
          let navigationBarHeight = $('header.construct-header').innerHeight();
          locationOffset = locationOffset - navigationBarHeight - 30;
          $('html, body').animate({
              scrollTop: locationOffset
          }, 300, 'swing');
      });
  }
});

$(function() {
  $('a[href*="#"]').on('click', function() {
      const scrollSpeed = 300;
      const navigationHeight = $("header.construct-header").innerHeight();
      const scrollToTarget = $(this.hash === '#' || '' ? 'html' : this.hash)
      if (!scrollToTarget.length) return;
      const scrollPosition = scrollToTarget.offset().top - navigationHeight - 30;
      $('html, body').animate({
          scrollTop: scrollPosition
      }, scrollSpeed, 'swing');
      return false;
  });
});


$(function () {
  // Scroll Hint
  new ScrollHint('.table-scroll-wrapper', {
    i18n: {
      scrollable: 'スクロールできます',
    },
  });
});

// 緊急のお知らせ
(function () {
  const elements = document.querySelectorAll('.urgent-notice');

  Array.from(elements).forEach(function (el) {
    //ボタンを取得
    const btn = el.querySelector('.urgent-notice__switch');
    const txt = el.querySelector('.urgent-text');
    //コンテンツを取得
    const content = el.querySelector('.urgent-notice__content');

    btn.addEventListener('click', function () {
      if (!content.classList.contains('open')) {
        content.style.maxHeight = content.scrollHeight + 'px';
        content.classList.add('open');
        txt.textContent = 'とじる';
      } else {
        content.style.maxHeight = '';
        content.classList.remove('open');
        txt.textContent = 'もっと見る';
      }
    });
  });
})();


